


.positionImage{
  width: 625px;
  padding: 30px;
  position: relative;
  height: 537px;
  margin-top:50px;
  margin-left:25%;
}

.positionImage img{
  position: absolute;
  left: 10%;
  top:10%;
  z-index: 3;
}

.wieBenIk{
  padding-top:3%;
  padding-bottom:5%;
  min-height: 75vh;
  background: #E6E6E6;


}

.dottedBackgroundSmall{
  background: transparent;
  background-image: radial-gradient(#A7A4A4 3px, transparent 0);
  background-size: 20px 20px;
  background-position: -22px -11px;
  color: white;
}

.postionDotted{
  position: absolute;
  top:125px;
  bottom:0;
  left:175px;
  width: 625px;
  height: 537px;
}

.wieBenikTekst{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.smallTekst{
  width: 100%;

}
.smallTekst h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 700;
  z-index: 99;
  position: relative;
}

.blueLine{
  background: #12D1FD;
  width: 400px;
  height: 20px;
  margin-top:11px;
  position: absolute;
  z-index: 1;
}

.smallTekst p{
  font-size: 20px;
}
