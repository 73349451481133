@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bigH2{
    font-size:5em;
    color:white;
    /* width:100%; */
    margin-left:15vw;
    z-index: 999;
    font-family: 'Lato', sans-serif;
}



.foto_container_responsive .container_foto{
  width:100%;
  min-height: auto;
  transition: 1s ease;
}
.foto_container_responsive .col{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.foto_container_responsive .col a{
  width:30%;
}




.bigH1{
  font-size:6.5em;
  font-weight: 900;
  color:white;
  /* width: 100%; */
  margin-left:15vw;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  line-height: .8em;
  font-family: 'Lato', sans-serif;
}
.fotoDubbel img{
  z-index: 99999;
  position: relative;

  }
.fotoDubbel {
  position: relative;
  width: 30vw;



}

.fotoDubbel::before{
  content: "";
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 100%;
  height: 100%;
  background: red;
  background-color: #292929;

  z-index: 1;
}

.fotoDubbel::after{

      content: "";
      position: absolute;
      top: 15%;
      left: 10%;
      width: 100%;
      height: 100%;
      background: transparent;
      background-image: radial-gradient(#A7A4A4 3.5px, transparent 0);
      background-size: 23px 23px;
      background-position: -19px -15px;

      z-index: 1;

}

.dottedBackground{
  background: transparent;
  background-image: radial-gradient(#292828 5px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -15px;
  color: white;
}
.positionLeftTop{
  height: 38vw;
  position: absolute;
  left: 0;
  width: 30vw;
  z-index: 2;
  top:7vw;
}


.buttonHome{
  background:#12D1FD ;
  padding: 10px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  width: 40%;
  margin-left:15vw;
  margin-top:5%;
  z-index: 2;
  width:20vw;
}


.IntroName{
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10%;
  height: 80%;
  width: 100vw;
}

.background-Intro{
  background: url(/static/media/backgroundSide.6e251153.jpg);
  background-size: cover;
  height:95vh;
  background-position: center;
  margin-left:2px;

}

.containerWerk{
  width: 75% !important;
  margin: 0 auto !important;
}

.containerKennis{
  width: 50% !important;
  margin: 0 auto !important;

}


.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.blueColor{
  background-color: #12D1FD;
}
.grey{
  background-color: #4A4A4A;
}


/* // <img src={process.env.PUBLIC_URL + 'motor_alone.jpg'} width="80%" height="auto" /> */




.positionImage{
  width: 625px;
  padding: 30px;
  position: relative;
  height: 537px;
  margin-top:50px;
  margin-left:25%;
}

.positionImage img{
  position: absolute;
  left: 10%;
  top:10%;
  z-index: 3;
}

.wieBenIk{
  padding-top:3%;
  padding-bottom:5%;
  min-height: 75vh;
  background: #E6E6E6;


}

.dottedBackgroundSmall{
  background: transparent;
  background-image: radial-gradient(#A7A4A4 3px, transparent 0);
  background-size: 20px 20px;
  background-position: -22px -11px;
  color: white;
}

.postionDotted{
  position: absolute;
  top:125px;
  bottom:0;
  left:175px;
  width: 625px;
  height: 537px;
}

.wieBenikTekst{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.smallTekst{
  width: 100%;

}
.smallTekst h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 700;
  z-index: 99;
  position: relative;
}

.blueLine{
  background: #12D1FD;
  width: 400px;
  height: 20px;
  margin-top:11px;
  position: absolute;
  z-index: 1;
}

.smallTekst p{
  font-size: 20px;
}

body{
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}
.row{
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0;
}

.container, .container-fluid{
  padding: 0 !important;
}

.containerMarginCenter{
  margin: 0 auto;
}

/* .row{
  margin:0 !important;
  padding: 0 !important;
  --bs-gutter:0 !imporant;
} */

.filter-container{
  margin: 0 auto;
}

.filter-item{
  float:left;
}


.navbar-nav{
  width: 40%;
  margin-right: 10%;
}

a{
  transition: 0.2s ease;
}

a:hover{
  color:#1D1E22 !important;
}

.navbar-nav a{
  color:white;
  padding: 0 20px ;
  width:100%;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-align: center;


}

.btn-BorderBottom{
  -webkit-text-decoration: underline #12D1FD ;
          text-decoration: underline #12D1FD ;
  text-underline-position: under;
  text-underline-offset: 3px;
  text-decoration-thickness:10px ;
}



.active{
  -webkit-text-decoration: underline #12D1FD !important;
          text-decoration: underline #12D1FD !important;
  text-underline-position: under!important;
  text-underline-offset: 3px!important;
  text-decoration-thickness:4px!important;
}

.centerFlex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingGemaakt{
  padding:100px 0 !important;
}

.smallPaddingBottom{
  padding-bottom: 50px;
}

.imgCarousel{
  width: 90%;
  height: auto;
}

.flexCenter{
  display: flex;
  justify-content: center;
}

.flexMiddle{
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingTopBottom{
  padding: 50px 0;
}

.slide{
  padding-left:50px;
  padding-right: 50px;
}
.PaddingWide{
  padding: 0 10vw;
}
.topRow{
  padding-top:0;
}




.fullWidthText .col{
  text-align: center;
  width: 100%;
}

.centerBlockButton{
  width: 100%;
  margin: 0 auto;
}
.smallFont{
  font-size: 30px !important;
}


.contactLine .sportstreep {
 width: 25%;
 left: 37.5vw;
 height: 40px;
 margin-bottom: -50px;
}

.contactLine h2{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 90px;
  text-transform: uppercase;
}






.btn-BorderBottom{
  font-family: 'Lato', sans-serif;
  background-color: transparent;
  border:none;
  font-size: 36px ;
  font-weight: 900 ;
  transition: 0.2s ease;
  margin-left:0;
  padding-left:0;
  text-transform: uppercase;

}

.btn-BorderBottom:hover{
  color: #12D1FD;
}

.paddingBottom{
  padding-bottom:100px;
}

.backgroundGray{
  background: #E6E6E6;
}

.titleMetStreep, .titleMetStreep h2, .titleMetStreep h3{
  position: relative;
  text-align: center;
  z-index: 99;

}
.titleMetStreep{
  padding-top:50px;
  margin-bottom:50px;
}


.titleMetStreep h2{
  font-family: 'Lato', sans-serif;
  font-size: 3.5em;
  margin-bottom: -15px;
  padding:0;

}

.titleMetStreep h3{
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 5em;
  font-weight: 900;
  margin-bottom:30px;
}


.sportstreep{
  position: absolute;
  height: 25%;
  width: 23vw;
  background: #12D1FD;
  left: 38vw;
}

.navbar-expand-lg, .navbar-collapse{
  justify-content: flex-end;
  /* padding-right: 100px; */
  padding: 0;
}

.navbar-nav{
  padding-right: 10%;
}

.center{
  text-align: center;
}

.backgroundDark{
  background:#1D1E22;
  color:white;
}

.bg-dark{
  background:#1D1E22 !important;
}

.navbar{
  background: #54595F;
  padding:0 !important;
}

header .col, header .col-5{
  padding: 10px;
  z-index: 1;
}

.centeredContent{
  text-align: center;
}


header{
    display: flex;
    width: 100%;
    justify-content: flex-end;



}
 header .navbar{
  width: 100%;
}



.textMoving{
  position: absolute;
  bottom:50px;
  transition: 0.3s ease;
  z-index: 99;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container_foto:hover .textMoving{
  bottom: 100px;
}

.listPortfolio {
  display: flex;
  align-items: center;
  width: 40% !important;
  flex-direction: row;
  list-style-type: none;
  margin:0 auto;
  text-align: center;
}
.listPortfolio li{
  width: 100%;
  padding:20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s ease;

}
.listPortfolio li:hover{
  color:#12D1FD;
}


.container_foto {
	background-color: rgba(0, 50, 61, 0.6);
	padding: 0;
	overflow: hidden;
	width: 600px;
  min-height:338px;
	margin: 5px;
  position: relative;
  float:left;
}



.container_foto h2 {
	color: #fff;
	font-weight: 800;
	font-size: 25px;
	border-bottom: #fff solid 2px;
}

.container_foto h4 {
	font-weight: 300;
	color: #fff;
	font-size: 16px;
}

.container_foto img {
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0.4;
	transition: all 2s ease;
}

.popUpBar {
	background-color: #12D1FD;
	position: absolute;
	width: 100%;
	height: 70px;
	bottom: 0;
	z-index: 1;
	opacity: 0;
	transform: translate(0px, 70px);
	-webkit-transform: translate(0px, 70px);
	-moz-transform: translate(0px, 70px);
	-o-transform: translate(0px, 70px);
	-ms-transform: translate(0px, 70px);
	transition: all 0.2s ease-in-out;
}

.popUpBar span {
	font-size: 30px;
	color: #fff;
	position: relative;
	margin: 0 auto;
	width: 100%;
	top: 13px;
  display: flex;
  justify-content: center;
  align-content: center;
}


/*hovers*/

.container_foto:hover {
	cursor: pointer;
}

.container_foto:hover img {
	opacity: 0.1;
	transform: scale(1.5);
}

.container_foto:hover article {
	transform: translate(2px, -69px);
	-webkit-transform: translate(2px, -69px);
	-moz-transform: translate(2px, -69px);
	-o-transform: translate(2px, -69px);
	-ms-transform: translate(2px, -69px);
}

.container_foto:hover .popUpBar {
	transform: translate(0px, 0px);
	-webkit-transform: translate(0px, 0px);
	-moz-transform: translate(0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	opacity: 1;
}


:root {
	--white: #ffffff;
	--black: #000000;
	--blue:#0288d1;
	--gray:#E6E6E6;
	--box-shadow1:0px 0px 18px 2px rgba(10, 55, 90, 0.15);
  --grayDark: #4a4a4a;
  --black: #000;
}


.timeline {
    padding: 50px 0;
    position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin:0 auto;
}
.timeline-nodes {
        padding-bottom: 25px;
        position: relative;
				width: 90vw;

    }

.timeline-nodes:nth-child(odd){
	margin-left: 10%;
}

.timeline-nodes:nth-child(even) {
    flex-direction: row-reverse;
		margin-right: 10%;
}
.timeline h3, .timeline p {
    padding: 5px 15px;
}
.timeline h3{
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;


}

.timeline-nodes:nth-child(2n+1) .timeline-content{
  background:#4a4a4a;
  background:var(--grayDark);
  color: #fff;
}
.timeline-nodes:nth-child(2n) .timeline-content{
  background:#E6E6E6;
  background:var(--gray);
  color: #000;
}

.timeline-nodes:nth-child(odd) ul{
	text-align: right;


}

.timeline-nodes ul{
	list-style-type: none;
	font-family: 'Montserrat', sans-serif;
}

.timeline-nodes ul li{
	font-size: 20px;
}

.timeline-nodes h3{
	font-weight: 900;
	font-size:32px;
}

.timeline-content {
	padding:25px 25px;
}


.timeline-nodes:nth-child(2n+1) .timeline-content ul{

}


.timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 16px dotted #E6E6E6;
    border-left: 16px dotted var(--gray);
    height: 100%;
    z-index: 1;
    transform: translateX(-50%);
}
.timeline-content {
    border: 1px solid var(--greyDark);
    position: relative;
    box-shadow: 0px 3px 25px 0px rgba(10, 55, 90, 0.2)
}
.timeline-nodes:nth-child(odd) h3,
.timeline-nodes:nth-child(odd) p {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-date {
    text-align: left;
}

.timeline-nodes:nth-child(even) .timeline-date {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 101%;
    width: 30px;
    border-top: 16px dotted #E6E6E6;
    border-top: 16px dotted var(--gray);

}
.timeline-nodes:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 101%;
  width: 30px;
  border-top: 16px dotted #E6E6E6;
  border-top: 16px dotted var(--gray);
}
.timeline-image {
    position: relative;
    z-index: 100;
}
.timeline-image::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 2px dotted var(--greyDark);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    z-index: 1;


}
.timeline-image img {
    position: relative;
    z-index: 100;
}


/*small device style*/
.foto_container_responsive{
  margin: 0 auto !important;
  width:60%;
}
.mobile{
  display: none;
  transition: 0.2s ease;
}
.mobile img{
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 1280px) {
  .buttonHome{
    width: 30vw;
  }
  .navbar-nav{
    margin:auto;
  }

  .containerKennis{
    width: 90% !important;


  }

  .fotoDubbel{
    width: 40vw;
    margin-top:50px;
  }
  .timeline-nodes:nth-child(even){
    margin-right:5% ;
  }
  .timeline-nodes:nth-child(odd){
    margin-left:5% ;
  }

  .mobileReverse{
    flex-direction: column-reverse;
  }

  .background-Intro{
    flex:auto;
    height: 50vh;
  }
  .positionLeftTop{

    top:57%;
  }

  .foto_container_responsive{
    width:100%;
  }
  .foto_container_responsive .col a{
    width:40%;
  }

  .sportstreep{
    width: 43vw;
    left:28vw;
  }

}


@media (max-width: 1024px) {
  .wieBenikTekst .smallTekst {
    width: 80%;
  }
  .carouselFotos{
    display: none;
  }
  .mobile{
    display: flex;
    flex-direction: column;
    --bs-gutter-x:1.5rem !important;
  }


  .contactLine .sportstreep{
    width: 30%;
    left: 35vw;
  }
  .paddingBottom{
    padding-bottom: 100px !important;
  }
  .containerKennis{
    width: 100% !important;
  }
  .contactSlide .slide{
    min-width: 20% !important;
  }

  .centerBlockButton{
    margin-top: 25px ;
  }


  .smallTekst{
    width: 100%;
    margin: 0 auto;
    margin: 100px auto;
  }
  .timeline-nodes:nth-child(even){
    margin-right:0% ;
  }
  .timeline-nodes:nth-child(odd){
    margin-left:0% ;
  }
  .sportstreep{
    width: 50vw;
    left:25vw;
  }

}


@media (max-width: 767px) {
  .buttonHome{
    width: 50vw;
  }
  .wieBenikTekst .smallTekst {
    width: 80%;
  }
  .navbar-nav{
    margin-right: 0;
    padding-right: 0;
    float:right;
  }

  .contactLine .sportstreep{
    width: 40%;
    left: 29.5vw;
  }
  .contactSlide .slide{
    min-width: 25% !important;
  }

  .bigH2{
    margin-top:50px;
    font-size: 3.8em;
  }
  .bigH1{
    font-size: 4.8em;
  }

  .fotoDubbel{
    width:50vw;
  }

  .sportstreep{
    width: 70vw;
    left:15vw;
  }

  .navbar-nav a{
    height: 50px;
    text-align: right;
    padding:0;
  }
  .navbar-nav a:first-child{
    margin-top:50px;

  }
  .navbar-toggler{
    float:right;
  }

  .foto_container_responsive .col a{
    width:80%;
  }
.timeline-nodes:nth-child(odd) h3, .timeline-nodes:nth-child(odd) ul {
    text-align: left
}

.timeline-nodes:nth-child(even) .timeline-content::after, .timeline-nodes:nth-child(odd) .timeline-content::after{
  display: none;
}

.timeline-nodes:nth-child(even) {
    flex-direction: row;
    /* margin-left:5% !important; */
    width: 80% !important;
}
.timeline-nodes:nth-child(odd){
  width: 80% !important;
}
    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 3%;
    width: 0;
    border-left: 16px dotted var(--gray);
    height: 100%;
    z-index: 1;
    transform: translateX(0);
}
.timeline h3 {
    font-size: 1.7rem;
}
.timeline p {
    font-size: 14px;
}
.timeline-image {
    position: absolute;
    left: 0%;
    top: 60px;
    /*transform: translateX(-50%;);*/
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 38%;
    left: auto;
    right: 100%;
    width: 40px;
    border-left: 40px;
    border-top: 16px dotted var(--gray);

}
.timeline-nodes:nth-child(even) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    right: 100%;
    width: 40px;
    border-top: 16px dotted var(--gray);
}
.timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
}
.timeline-image::before {
    width: 65px;
    height: 65px;
}
}

/*extra small device style */
@media (max-width: 575px) {
  .background-Intro{
    width: 99.5vw;
  }



  .listPortfolio{
    width: 100% !important;
  }
  .container_foto{
    width:500px;
    min-height: 280px;
  }
  .contactLine .sportstreep{
    width: 55%;
    left: 22.5vw;
  }

  .contactSlide .slide{
    min-width: 35% !important;
  }

  .sportstreep{
    width: 90vw;
    left:5vw;
  }

  .fotoDubbel{
    width:60vw;
  }

  .bigH2{
    margin-top:30px;
    font-size: 2.5em;
  }
  .bigH1{
    font-size: 3em;
  }
  .buttonHome{
    width: 75%;
  }


    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 3%;
}
.timeline-image {
    position: absolute;
    left: -5%;
    }
.timeline-image::before {
    width: 60px;
    height: 60px;
}
}

@media (max-width: 465px) {
  .blueLine{
    width: 83vw;
  }
  .contactSlide .slide{
    min-width: 50% !important;
  }
  .titleMetStreep h3{
    font-size: 4em;
  }
  .container_foto{
    width: 97vw;
    min-height: 250px;
  }
}
@media (max-width: 420px) {
  .container_foto{
    width: 80vw;
    min-height: 175px;
  }
  .smallFont{
    font-size: 27px !important;
  }
}

