
:root {
	--white: #ffffff;
	--black: #000000;
	--blue:#0288d1;
	--gray:#E6E6E6;
	--box-shadow1:0px 0px 18px 2px rgba(10, 55, 90, 0.15);
  --grayDark: #4a4a4a;
  --black: #000;
}


.timeline {
    padding: 50px 0;
    position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin:0 auto;
}
.timeline-nodes {
        padding-bottom: 25px;
        position: relative;
				width: 90vw;

    }

.timeline-nodes:nth-child(odd){
	margin-left: 10%;
}

.timeline-nodes:nth-child(even) {
    flex-direction: row-reverse;
		margin-right: 10%;
}
.timeline h3, .timeline p {
    padding: 5px 15px;
}
.timeline h3{
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;


}

.timeline-nodes:nth-child(2n+1) .timeline-content{
  background:var(--grayDark);
  color: #fff;
}
.timeline-nodes:nth-child(2n) .timeline-content{
  background:var(--gray);
  color: #000;
}

.timeline-nodes:nth-child(odd) ul{
	text-align: right;


}

.timeline-nodes ul{
	list-style-type: none;
	font-family: 'Montserrat', sans-serif;
}

.timeline-nodes ul li{
	font-size: 20px;
}

.timeline-nodes h3{
	font-weight: 900;
	font-size:32px;
}

.timeline-content {
	padding:25px 25px;
}


.timeline-nodes:nth-child(2n+1) .timeline-content ul{

}


.timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 16px dotted var(--gray);
    height: 100%;
    z-index: 1;
    transform: translateX(-50%);
}
.timeline-content {
    border: 1px solid var(--greyDark);
    position: relative;
    box-shadow: 0px 3px 25px 0px rgba(10, 55, 90, 0.2)
}
.timeline-nodes:nth-child(odd) h3,
.timeline-nodes:nth-child(odd) p {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-date {
    text-align: left;
}

.timeline-nodes:nth-child(even) .timeline-date {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 101%;
    width: 30px;
    border-top: 16px dotted var(--gray);

}
.timeline-nodes:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 101%;
  width: 30px;
  border-top: 16px dotted var(--gray);
}
.timeline-image {
    position: relative;
    z-index: 100;
}
.timeline-image::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 2px dotted var(--greyDark);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    z-index: 1;


}
.timeline-image img {
    position: relative;
    z-index: 100;
}
