.bigH2{
    font-size:5em;
    color:white;
    /* width:100%; */
    margin-left:15vw;
    z-index: 999;
    font-family: 'Lato', sans-serif;
}



.foto_container_responsive .container_foto{
  width:100%;
  min-height: auto;
  transition: 1s ease;
}
.foto_container_responsive .col{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.foto_container_responsive .col a{
  width:30%;
}




.bigH1{
  font-size:6.5em;
  font-weight: 900;
  color:white;
  /* width: 100%; */
  margin-left:15vw;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  line-height: .8em;
  font-family: 'Lato', sans-serif;
}
.fotoDubbel img{
  z-index: 99999;
  position: relative;

  }
.fotoDubbel {
  position: relative;
  width: 30vw;



}

.fotoDubbel::before{
  content: "";
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 100%;
  height: 100%;
  background: red;
  background-color: #292929;

  z-index: 1;
}

.fotoDubbel::after{

      content: "";
      position: absolute;
      top: 15%;
      left: 10%;
      width: 100%;
      height: 100%;
      background: transparent;
      background-image: radial-gradient(#A7A4A4 3.5px, transparent 0);
      background-size: 23px 23px;
      background-position: -19px -15px;

      z-index: 1;

}

.dottedBackground{
  background: transparent;
  background-image: radial-gradient(#292828 5px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -15px;
  color: white;
}
.positionLeftTop{
  height: 38vw;
  position: absolute;
  left: 0;
  width: 30vw;
  z-index: 2;
  top:7vw;
}


.buttonHome{
  background:#12D1FD ;
  padding: 10px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  width: 40%;
  margin-left:15vw;
  margin-top:5%;
  z-index: 2;
  width:20vw;
}


.IntroName{
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10%;
  height: 80%;
  width: 100vw;
}

.background-Intro{
  background: url('../img/backgroundSide.jpg');
  background-size: cover;
  height:95vh;
  background-position: center;
  margin-left:2px;

}

.containerWerk{
  width: 75% !important;
  margin: 0 auto !important;
}

.containerKennis{
  width: 50% !important;
  margin: 0 auto !important;

}


.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.blueColor{
  background-color: #12D1FD;
}
.grey{
  background-color: #4A4A4A;
}


/* // <img src={process.env.PUBLIC_URL + 'motor_alone.jpg'} width="80%" height="auto" /> */
