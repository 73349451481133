

.textMoving{
  position: absolute;
  bottom:50px;
  transition: 0.3s ease;
  z-index: 99;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container_foto:hover .textMoving{
  bottom: 100px;
}

.listPortfolio {
  display: flex;
  align-items: center;
  width: 40% !important;
  flex-direction: row;
  list-style-type: none;
  margin:0 auto;
  text-align: center;
}
.listPortfolio li{
  width: 100%;
  padding:20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s ease;

}
.listPortfolio li:hover{
  color:#12D1FD;
}


.container_foto {
	background-color: rgba(0, 50, 61, 0.6);
	padding: 0;
	overflow: hidden;
	width: 600px;
  min-height:338px;
	margin: 5px;
  position: relative;
  float:left;
}



.container_foto h2 {
	color: #fff;
	font-weight: 800;
	font-size: 25px;
	border-bottom: #fff solid 2px;
}

.container_foto h4 {
	font-weight: 300;
	color: #fff;
	font-size: 16px;
}

.container_foto img {
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0.4;
	-webkit-transition: all 2s ease;
	-moz-transition: all 2s ease;
	-o-transition: all 2s ease;
	-ms-transition: all 2s ease;
	transition: all 2s ease;
}

.popUpBar {
	background-color: #12D1FD;
	position: absolute;
	width: 100%;
	height: 70px;
	bottom: 0;
	z-index: 1;
	opacity: 0;
	transform: translate(0px, 70px);
	-webkit-transform: translate(0px, 70px);
	-moz-transform: translate(0px, 70px);
	-o-transform: translate(0px, 70px);
	-ms-transform: translate(0px, 70px);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.popUpBar span {
	font-size: 30px;
	color: #fff;
	position: relative;
	margin: 0 auto;
	width: 100%;
	top: 13px;
  display: flex;
  justify-content: center;
  align-content: center;
}


/*hovers*/

.container_foto:hover {
	cursor: pointer;
}

.container_foto:hover img {
	opacity: 0.1;
	transform: scale(1.5);
}

.container_foto:hover article {
	transform: translate(2px, -69px);
	-webkit-transform: translate(2px, -69px);
	-moz-transform: translate(2px, -69px);
	-o-transform: translate(2px, -69px);
	-ms-transform: translate(2px, -69px);
}

.container_foto:hover .popUpBar {
	transform: translate(0px, 0px);
	-webkit-transform: translate(0px, 0px);
	-moz-transform: translate(0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	opacity: 1;
}
