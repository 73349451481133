/*small device style*/
.foto_container_responsive{
  margin: 0 auto !important;
  width:60%;
}
.mobile{
  display: none;
  transition: 0.2s ease;
}
.mobile img{
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 1280px) {
  .buttonHome{
    width: 30vw;
  }
  .navbar-nav{
    margin:auto;
  }

  .containerKennis{
    width: 90% !important;


  }

  .fotoDubbel{
    width: 40vw;
    margin-top:50px;
  }
  .timeline-nodes:nth-child(even){
    margin-right:5% ;
  }
  .timeline-nodes:nth-child(odd){
    margin-left:5% ;
  }

  .mobileReverse{
    flex-direction: column-reverse;
  }

  .background-Intro{
    flex:auto;
    height: 50vh;
  }
  .positionLeftTop{

    top:57%;
  }

  .foto_container_responsive{
    width:100%;
  }
  .foto_container_responsive .col a{
    width:40%;
  }

  .sportstreep{
    width: 43vw;
    left:28vw;
  }

}


@media (max-width: 1024px) {
  .wieBenikTekst .smallTekst {
    width: 80%;
  }
  .carouselFotos{
    display: none;
  }
  .mobile{
    display: flex;
    flex-direction: column;
    --bs-gutter-x:1.5rem !important;
  }


  .contactLine .sportstreep{
    width: 30%;
    left: 35vw;
  }
  .paddingBottom{
    padding-bottom: 100px !important;
  }
  .containerKennis{
    width: 100% !important;
  }
  .contactSlide .slide{
    min-width: 20% !important;
  }

  .centerBlockButton{
    margin-top: 25px ;
  }


  .smallTekst{
    width: 100%;
    margin: 0 auto;
    margin: 100px auto;
  }
  .timeline-nodes:nth-child(even){
    margin-right:0% ;
  }
  .timeline-nodes:nth-child(odd){
    margin-left:0% ;
  }
  .sportstreep{
    width: 50vw;
    left:25vw;
  }

}


@media (max-width: 767px) {
  .buttonHome{
    width: 50vw;
  }
  .wieBenikTekst .smallTekst {
    width: 80%;
  }
  .navbar-nav{
    margin-right: 0;
    padding-right: 0;
    float:right;
  }

  .contactLine .sportstreep{
    width: 40%;
    left: 29.5vw;
  }
  .contactSlide .slide{
    min-width: 25% !important;
  }

  .bigH2{
    margin-top:50px;
    font-size: 3.8em;
  }
  .bigH1{
    font-size: 4.8em;
  }

  .fotoDubbel{
    width:50vw;
  }

  .sportstreep{
    width: 70vw;
    left:15vw;
  }

  .navbar-nav a{
    height: 50px;
    text-align: right;
    padding:0;
  }
  .navbar-nav a:first-child{
    margin-top:50px;

  }
  .navbar-toggler{
    float:right;
  }

  .foto_container_responsive .col a{
    width:80%;
  }
.timeline-nodes:nth-child(odd) h3, .timeline-nodes:nth-child(odd) ul {
    text-align: left
}

.timeline-nodes:nth-child(even) .timeline-content::after, .timeline-nodes:nth-child(odd) .timeline-content::after{
  display: none;
}

.timeline-nodes:nth-child(even) {
    flex-direction: row;
    /* margin-left:5% !important; */
    width: 80% !important;
}
.timeline-nodes:nth-child(odd){
  width: 80% !important;
}
    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 3%;
    width: 0;
    border-left: 16px dotted var(--gray);
    height: 100%;
    z-index: 1;
    transform: translateX(0);
}
.timeline h3 {
    font-size: 1.7rem;
}
.timeline p {
    font-size: 14px;
}
.timeline-image {
    position: absolute;
    left: 0%;
    top: 60px;
    /*transform: translateX(-50%;);*/
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 38%;
    left: auto;
    right: 100%;
    width: 40px;
    border-left: 40px;
    border-top: 16px dotted var(--gray);

}
.timeline-nodes:nth-child(even) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    right: 100%;
    width: 40px;
    border-top: 16px dotted var(--gray);
}
.timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
}
.timeline-image::before {
    width: 65px;
    height: 65px;
}
}

/*extra small device style */
@media (max-width: 575px) {
  .background-Intro{
    width: 99.5vw;
  }



  .listPortfolio{
    width: 100% !important;
  }
  .container_foto{
    width:500px;
    min-height: 280px;
  }
  .contactLine .sportstreep{
    width: 55%;
    left: 22.5vw;
  }

  .contactSlide .slide{
    min-width: 35% !important;
  }

  .sportstreep{
    width: 90vw;
    left:5vw;
  }

  .fotoDubbel{
    width:60vw;
  }

  .bigH2{
    margin-top:30px;
    font-size: 2.5em;
  }
  .bigH1{
    font-size: 3em;
  }
  .buttonHome{
    width: 75%;
  }


    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 3%;
}
.timeline-image {
    position: absolute;
    left: -5%;
    }
.timeline-image::before {
    width: 60px;
    height: 60px;
}
}

@media (max-width: 465px) {
  .blueLine{
    width: 83vw;
  }
  .contactSlide .slide{
    min-width: 50% !important;
  }
  .titleMetStreep h3{
    font-size: 4em;
  }
  .container_foto{
    width: 97vw;
    min-height: 250px;
  }
}
@media (max-width: 420px) {
  .container_foto{
    width: 80vw;
    min-height: 175px;
  }
  .smallFont{
    font-size: 27px !important;
  }
}
