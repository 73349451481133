@import url( "https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap" rel="stylesheet");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap' rel="stylesheet");



body{
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}
.row{
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0;
}

.container, .container-fluid{
  padding: 0 !important;
}

.containerMarginCenter{
  margin: 0 auto;
}

/* .row{
  margin:0 !important;
  padding: 0 !important;
  --bs-gutter:0 !imporant;
} */

.filter-container{
  margin: 0 auto;
}

.filter-item{
  float:left;
}


.navbar-nav{
  width: 40%;
  margin-right: 10%;
}

a{
  transition: 0.2s ease;
}

a:hover{
  color:#1D1E22 !important;
}

.navbar-nav a{
  color:white;
  padding: 0 20px ;
  width:100%;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-align: center;


}

.btn-BorderBottom{
  text-decoration: underline #12D1FD ;
  text-underline-position: under;
  text-underline-offset: 3px;
  text-decoration-thickness:10px ;
}



.active{
  text-decoration: underline #12D1FD !important;
  text-underline-position: under!important;
  text-underline-offset: 3px!important;
  text-decoration-thickness:4px!important;
}

.centerFlex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingGemaakt{
  padding:100px 0 !important;
}

.smallPaddingBottom{
  padding-bottom: 50px;
}

.imgCarousel{
  width: 90%;
  height: auto;
}

.flexCenter{
  display: flex;
  justify-content: center;
}

.flexMiddle{
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingTopBottom{
  padding: 50px 0;
}

.slide{
  padding-left:50px;
  padding-right: 50px;
}
.PaddingWide{
  padding: 0 10vw;
}
.topRow{
  padding-top:0;
}




.fullWidthText .col{
  text-align: center;
  width: 100%;
}

.centerBlockButton{
  width: 100%;
  margin: 0 auto;
}
.smallFont{
  font-size: 30px !important;
}


.contactLine .sportstreep {
 width: 25%;
 left: 37.5vw;
 height: 40px;
 margin-bottom: -50px;
}

.contactLine h2{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 90px;
  text-transform: uppercase;
}






.btn-BorderBottom{
  font-family: 'Lato', sans-serif;
  background-color: transparent;
  border:none;
  font-size: 36px ;
  font-weight: 900 ;
  transition: 0.2s ease;
  margin-left:0;
  padding-left:0;
  text-transform: uppercase;

}

.btn-BorderBottom:hover{
  color: #12D1FD;
}

.paddingBottom{
  padding-bottom:100px;
}

.backgroundGray{
  background: #E6E6E6;
}

.titleMetStreep, .titleMetStreep h2, .titleMetStreep h3{
  position: relative;
  text-align: center;
  z-index: 99;

}
.titleMetStreep{
  padding-top:50px;
  margin-bottom:50px;
}


.titleMetStreep h2{
  font-family: 'Lato', sans-serif;
  font-size: 3.5em;
  margin-bottom: -15px;
  padding:0;

}

.titleMetStreep h3{
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 5em;
  font-weight: 900;
  margin-bottom:30px;
}


.sportstreep{
  position: absolute;
  height: 25%;
  width: 23vw;
  background: #12D1FD;
  left: 38vw;
}

.navbar-expand-lg, .navbar-collapse{
  justify-content: flex-end;
  /* padding-right: 100px; */
  padding: 0;
}

.navbar-nav{
  padding-right: 10%;
}

.center{
  text-align: center;
}

.backgroundDark{
  background:#1D1E22;
  color:white;
}

.bg-dark{
  background:#1D1E22 !important;
}

.navbar{
  background: #54595F;
  padding:0 !important;
}

header .col, header .col-5{
  padding: 10px;
  z-index: 1;
}

.centeredContent{
  text-align: center;
}


header{
    display: flex;
    width: 100%;
    justify-content: flex-end;



}
 header .navbar{
  width: 100%;
}
